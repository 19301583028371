import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Testimonials = () => {
	const data = useStaticQuery(graphql`
		query TestimonialsQuery {
			directus {
				testimonials(filter: { status: { _eq: "published" } }) {
					job
					id
					content
					author
				}
			}
		}
	`);

	const testimonial =
		data.directus.testimonials[
			Math.floor(Math.random() * data.directus.testimonials.length)
		];

	return (
		<section className="py-24 bg-gray">
			<div className="max-w-5xl mx-auto px-8">
				<blockquote>
					<div>
						<svg
							className="h-12 w-12 text-white opacity-25"
							fill="currentColor"
							viewBox="0 0 32 32"
							aria-hidden="true"
						>
							<path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
						</svg>
						<p className="mt-6 text-2xl font-medium text-white">
							{testimonial.content}
						</p>
					</div>
					<footer className="mt-6">
						<p className="text-base font-medium text-white">
							{testimonial.author}
						</p>
						<p className="text-base font-medium text-muted">
							{testimonial.job}
						</p>
					</footer>
				</blockquote>
			</div>
		</section>
	);
};

export default Testimonials;
