import * as React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { Seo } from "../components/seo";
import ExtendedTitle from "../components/extended-title";
import Heading from "../components/heading";
import SubHeading from "../components/subheading";
import {
	ClipboardDocumentCheckIcon,
	RocketLaunchIcon,
	ChartPieIcon,
} from "@heroicons/react/24/outline";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Cta from "../components/cta";
import Button from "../components/button";
import Testimonials from "../components/testimonials";

const services = [
	{
		title: "Strategie",
		content:
			"Jij hebt vast een eigen contentstrategie (en zo niet helpen we je daar graag bij). Hoe zorgen we ervoor dat we de content maken die daar precies bij aansluit? Dat leggen we vast in het strategiedocument.",
	},
	{
		title: "Budget",
		content:
			"Welk budget heb je nodig to get the job done, en welk budget wil je hiervoor vrij maken? Samen bekijken we de mogelijkheden en beschrijven we de ideale mix.",
	},
	{
		title: "Planning",
		content:
			"Welke fotografen willen we, welke kunnen er, en welke matchen met ons budget? We gaan de planning uitwerken tot alle puzzelstukjes op zijn plek vallen.",
	},
	{
		title: "Overeenkomsten",
		content:
			"Wij leggen de fotografen vast en sluiten een overeenkomst van opdracht met ze zodat jij je geen zorgen hoeft te maken over intellectuele eigendomsrechten of andere addertjes onder het gras.",
	},
	{
		title: "Briefing",
		content:
			"Ruim voor de productie bereiden wij de fotografen voor, zodat iedereen precies weet wat er van hen verwacht wordt en niets aan het toeval wordt overgelaten.",
	},
	{
		title: "Aansturing",
		content:
			"We ontvangen de fotografen, sturen ze gedurende de hele productie aan en zijn er als contactpersoon of uitvalbasis als dat nodig is. Zo zetten we iedereen op de juiste plek, en zorgen we dat alles ook volgens planning ter uitvoering komt.",
	},
	{
		title: "Beeldverwerking & beheer",
		content:
			"We zorgen dat al die honderden, zo niet (tien)duizenden foto’s bij elkaar komen, gesorteerd en uitgezocht worden. We hebben de schat binnen en daar zijn we zuinig op, al moeten we de muntstukjes nog even afpoetsen",
	},
	{
		title: "Uitvoering",
		content:
			"We bewerken het beeld volgens de in het strategiedocument vastgelegde stijl, en zorgen dat het zo snel mogelijk wordt opgeleverd. Vaak binnen 48 uur, of zelfs sneller.",
	},
	{
		title: "Evaluatie",
		content:
			"Na oplevering evalueren we graag met jullie hoe de productie verlopen is. Zijn er nog lessen te trekken? Dan beschrijven we die, en implementeren we die de volgende keer.",
	},
	{
		title: "Finance",
		content:
			"Wij doen de gehele financiële administratie die bij een fotografieproductie komt kijken. We zorgen dat alle fotografen direct betaald krijgen, en sturen onze opdrachtgever één simpele factuur.",
	},
];

const types = [
	{
		icon: <ClipboardDocumentCheckIcon className="w-12 h-12 block my-4" />,
		title: "Pre-productie",
		list: [
			"Strategiebepaling",
			"Budgetbepaling",
			"Planning",
			"Contracteren fotografen",
			"Briefing fotografen",
		],
	},
	{
		icon: <RocketLaunchIcon className="w-12 h-12 block my-4" />,
		title: "Uitvoering",
		list: [
			"Ontvangst fotografen",
			"Aansturing",
			"Registratie / fotograferen",
			"Direct aanleveren beelden",
		],
	},
	{
		icon: <ChartPieIcon className="w-12 h-12 block my-4" />,
		title: "Post-productie",
		list: [
			"Beeld spotten",
			"Beeld bewerken",
			"Beeld bundelen",
			"Showcase album",
			"Evaluatie",
			"Finance",
		],
	},
];

const CoordinationPage = () => {
	const data = useStaticQuery(graphql`
		query CoordinationQuery {
			directus {
				hero_images(filter: { location: { _eq: "coordination" } }) {
					image {
						id
						imageFile {
							childImageSharp {
								gatsbyImageData(width: 1200, quality: 75)
							}
						}
					}
				}
				cases {
					images(limit: 5) {
						directus_files_id {
							id
							imageFile {
								childImageSharp {
									gatsbyImageData(width: 400, quality: 75)
								}
							}
						}
					}
				}
			}
		}
	`);
	return (
		<>
			<Layout>
				<main className="divide-y divide-gray">
					<ExtendedTitle
						backButtonTo="/portfolio"
						backButtonTitle="Bekijk portfolio"
						subtitle="Goed gedaan in plaats van goed gegaan"
						image={data.directus.hero_images[0].image}
					>
						Coördinatie
					</ExtendedTitle>
					<section className="bg-off-white py-48">
						<div className="max-w-6xl mx-auto px-8">
							<p className="max-w-4xl mx-auto text-xl md:text-2xl leading-normal mb-16 md:text-center font-bold">
								Je team is zo sterk als de zwakste schakel. Maar
								hoe zorg je ervoor dat die zwakste schakel
								schittert? Bij Spark verzorgen we al sinds 2016
								de content productie en coördinatie voor kleine
								en grote projecten.
							</p>
							<div className="md:columns-2 text-base gap-16">
								<p className="mb-6">
									Met ruim 50 vaste freelancers en 5 partners
									is Spark je spin in het web. De schakel
									tussen jou als opdrachtgever en het doel dat
									we gezamenlijk willen halen. Want geen maker
									is hetzelfde. Door de juiste expertises te
									combineren van Nederlands beste fotografen
									behalen we altijd het beste eindresultaat.
								</p>
								<p className="mb-6">
									Dagelijks zijn we bezig met fotografie. De
									markt kennen we dan ook op ons duimpje. De
									juiste fotograaf met de juiste
									specialisaties hebben we dan ook snel
									gevonden. Door onze ervaring weten we
									precies wie we voor elk onderdeel moeten
									hebben. En niet onbelangrijk: binnen je
									budget. Direct checken we de beschikbaarheid
									en gaan we jouw dreamteam samenstellen. Of
									we nu 1 of 30 makers nodig hebben, we kunnen
									het snel en ad hoc organiseren.
								</p>
								<p className="mb-6">
									Fotograaf X die zijn parkeerticket is
									vergeten? Fotograaf Y die geen bonnen voor
									de crewcatering heeft gehad? Fotograaf Z die
									van stage wil wisselen en paniek omdat er
									adhoc iets in de planning geschoten wordt?
									Een team aansturen doet het marketingteam er
									niet even bij en heeft bovendien ook andere
									verantwoordelijkheden. Daarom doen we bij
									grote opdrachten ook de coördinatie op
									locatie. We voelen ons verantwoordelijk, en
									laten dat ook graag zien. Nadat we je
									sterrenteam gevonden en gebriefd hebben,
									houden we de controle op locatie. Zodat we
									zeker weten dat jouw wensenlijst een succes
									wordt.
								</p>
								<p className="mb-6">
									Als een spam-filter voorkomen we dat elk
									probleem door meerdere personen herhaalt
									wordt en op jouw bordje terecht komt.
									Overige uitdagingen communiceren we graag,
									maar we merken dat deze vrijwel altijd
									direct opgelost kunnen worden. Meer ruimte
									voor jou om jouw prioriteiten op locatie
									aandacht te geven.
								</p>
							</div>
							<div className="text-center">
								<Button
									to="/contact"
									dark={true}
									className="mt-16 mx-auto"
								>
									Neem contact op
								</Button>
							</div>
						</div>
					</section>
					<section className="bg-gray text-white text-center h-[50vh] md:h-[70vh] md:min-h-[700px] overflow-hidden relative">
						<div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-2 md:gap-4 -rotate-12 absolute origin-top-left -left-1/4">
							{data.directus.cases.map((item, i) =>
								item.images.map((image, i) => (
									<div key={image.directus_files_id.id}>
										<GatsbyImage
											imgClassName="rounded-lg"
											className="mb-4 object-cover h-full w-auto rounded-lg"
											alt="Spark"
											image={getImage(
												image.directus_files_id
													.imageFile
											)}
										/>
									</div>
								))
							)}
						</div>
					</section>
					<section className="bg-off-white py-48">
						<div className="container mx-auto">
							<Heading as="h2" className="mb-2">
								Coördinatie van a tot z
							</Heading>
							<SubHeading className="mb-16">
								Goed gedaan in plaats van goed gegaan
							</SubHeading>

							<div className="grid md:grid-cols-3 gap-16">
								{types.map((item, i) => (
									<div key={item.title}>
										{item.icon}
										<Heading as="h3" className="mb-4">
											{item.title}
										</Heading>
										<ul>
											{item.list.map((item, i) => (
												<li key={i}>{item}</li>
											))}
										</ul>
									</div>
								))}
							</div>
							<div className="text-center">
								<Button
									to="/contact"
									dark={true}
									className="mt-16 mx-auto"
								>
									Neem contact op
								</Button>
							</div>
						</div>
					</section>

					<Testimonials />

					<section className="bg-off-white py-48">
						<div className="container mx-auto text-center">
							<Heading as="h2" className="mb-2">
								Alle diensten onder Coordinatie
							</Heading>
							<p className="text-xl">
								Altijd inclusief de service van Spark
							</p>
						</div>
						<div>
							<ol className="relative w-full flex items-start gap-6 snap-x snap-mandatory overflow-auto my-16 pb-16">
								<li className="snap-center">
									<div className="w-[20vw] h-24 block"></div>
								</li>
								{services.map((item, i) => (
									<li
										className="snap-center group last:pr-48"
										key={i}
									>
										<div className="relative w-48 md:w-96">
											<div className="flex items-center">
												<div className="flex z-10 justify-center items-center w-6 h-6 bg-gray rounded-full ring-0 ring-off-white text-white sm:ring-8 shrink-0">
													{i + 1}
												</div>
												<div className="group-last:hidden flex w-full bg-gray h-px"></div>
											</div>
											<div className="mt-4 sm:pr-8">
												<h3 className="text-lg font-bold mb-3 text-gray">
													{item.title}
												</h3>
												<p className="text-base font-normal text-gray">
													{item.content}
												</p>
											</div>
										</div>
									</li>
								))}
							</ol>
						</div>
						<div className="text-center">
							<Button
								to="/contact"
								dark={true}
								className="mt-16 mx-auto"
							>
								Neem contact op
							</Button>
						</div>
					</section>
				</main>
				<Cta />
			</Layout>
		</>
	);
};

export default CoordinationPage;

export const Head = () => <Seo title="Coordinatie - SPARK" />;
